@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind Helpers */
.tw-input-field{
  @apply flex-1 focus:ring-indigo-500 focus:border-indigo-500 block   min-w-0  w-full rounded-none rounded-r-md sm:text-sm border-gray-300
}

.tw-table-header {
  @apply px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
}

.tw-slide-form-label{
  @apply block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2
}

.tw-link {
  @apply relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500
}

/* Search input */
.react-autosuggest__container {
  position: relative;
  margin-bottom: 0;
  margin-right: 20px;
}

.react-autosuggest__input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  margin-bottom:0;
  margin-right:10px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  box-sizing: border-box;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__section-title {
  padding: 10px 20px;
  font-weight: 600;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  font-weight:400;

}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}